const layers = {
  background: [
  {
    id: 'park',
    label: 'Sunny Park',
    thumbnail: './images/thumb_bg_D.png',
    src: './images/preview_bg_D.png',
    src_bgElem: './images/preview_bgElem_D.png',
    src_fg: './images/preview_fg_D.png',
    print: './export/print_background_D.png',
    print_card: './export/print_card_D.png'
  },
  {
    id: 'sakura',
    label: 'Cherry Blossoms',
    thumbnail: './images/thumb_bg_E.png',
    src: './images/preview_bg_E.png',
    src_bgElem: './images/preview_bgElem_E.png',
    src_fg: './images/preview_fg_E.png',
    print: './export/print_background_E.png',
    print_card: './export/print_card_E.png'
  },
  {
    id: 'city',
    label: 'City Holiday',
    thumbnail: './images/thumb_bg_C.png',
    src: './images/preview_bg_C.png',
    src_bgElem: './images/preview_bgElem_C.png',
    src_fg: './images/preview_fg_C.png',
    print: './export/print_background_C.png',
    print_card: './export/print_card_C.png'
  },
  {
    id: 'winter',
    label: 'Winter Wonderland',
    thumbnail: './images/thumb_bg_A.png',
    src: './images/preview_bg_A.png',
    src_bgElem: './images/preview_bgElem_A.png',
    src_fg: './images/preview_fg_A.png',
    print: './export/print_background_A.png',
    print_card: './export/print_card_A.png'
  },
  {
    id: 'fireplace',
    label: 'Warm Fireplace',
    thumbnail: './images/thumb_bg_B.png',
    src: './images/preview_bg_B.png',
    src_bgElem: './images/preview_bgElem_B.png',
    src_fg: './images/preview_fg_B.png',
    print: './export/print_background_B.png',
    print_card: './export/print_card_B.png'
  }
],
base: [
  {
    id: 'wood',
    label: 'Wood',
    thumbnail: './images/thumb_base_A.png',
    src: './images/preview_base_A.png',
    print: './export/print_base_A.png'
  },
  {
    id: 'blk',
    label: 'Black',
    thumbnail: './images/thumb_base_B.png',
    src: './images/preview_base_B.png',
    print: './export/print_base_B.png'
  },
  {
    id: 'gold',
    label: 'Gold',
    thumbnail: './images/thumb_base_C.png',
    src: './images/preview_base_C.png',
    print: './export/print_base_C.png'
  },
  {
    id: 'white',
    label: 'White',
    thumbnail: './images/thumb_base_D.png',
    src: './images/preview_base_D.png',
    print: './export/print_base_D.png'
  }
],
character: [
      {
        id: '',
        label: 'No character',
        src: '',
        print: ''
      },
      {
        id: 'blank',
        label: 'Custom (blank template)',
        src: './images/preview_chara_custom.png',
        print: './export/print_chara_blank.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- Jujutsu Kaisen --',
        divider: true,
      },
      {
        id: 'jjk_megumi',
        label: 'Fushiguro Megumi',
        src: './images/preview_chara_jjk_01_megumi.png',
        print: './export/print_chara_jjk_01_megumi.png'
      },
      {
        id: 'jjk_yuuji',
        label: 'Itadori Yuuji',
        src: './images/preview_chara_jjk_02_yuuji.png',
        print: './export/print_chara_jjk_02_yuuji.png'
      },
      {
        id: 'jjk_nobara',
        label: 'Kugisaki Nobara',
        src: './images/preview_chara_jjk_03_nobara.png',
        print: './export/print_chara_jjk_03_nobara.png'
      },
      {
        id: 'jjk_ddb',
        label: 'Divine Dog (Black)',
        src: './images/preview_chara_jjk_04_dogblack.png',
        print: './export/print_chara_jjk_04_dogblack.png'
      },
      {
        id: 'jjk_ddw',
        label: 'Divine Dog (White)',
        src: './images/preview_chara_jjk_05_dogwhite.png',
        print: './export/print_chara_jjk_05_dogwhite.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- Magi (Alikou) --',
        divider: true,
      },
      {
        id: 'magi_alibaba',
        label: 'Alibaba Saluja',
        src: './images/preview_chara_magi_01_alibaba.png',
        print: './export/print_chara_magi_01_alibaba.png'
      },
      {
        id: 'magi_kougyoku',
        label: 'Ren Kougyoku',
        src: './images/preview_chara_magi_02_kougyoku.png',
        print: './export/print_chara_magi_02_kougyoku.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- MDZS / The Untamed --',
        divider: true,
      },
      {
        id: 'mdzs_aq',
        label: 'A-Qing',
        src: './images/preview_chara_mdzs_01_aq.png',
        print: './export/print_chara_mdzs_01_aq.png'
      },
      {
        id: 'mdzs_fairy',
        label: 'Fairy',
        src: './images/preview_chara_mdzs_02_fairy.png',
        print: './export/print_chara_mdzs_02_fairy.png'
      },
      {
        id: 'mdzs_jc',
        label: 'Jiang Cheng',
        src: './images/preview_chara_mdzs_03_jc.png',
        print: './export/print_chara_mdzs_03_jc.png'
      },
      {
        id: 'mdzs_jyl',
        label: 'Jiang Yanli',
        src: './images/preview_chara_mdzs_04_jyl.png',
        print: './export/print_chara_mdzs_04_jyl.png'
      },
      {
        id: 'mdzs_jgy',
        label: 'Jin Guangyao',
        src: './images/preview_chara_mdzs_05_jgy.png',
        print: './export/print_chara_mdzs_05_jgy.png'
      },
      {
        id: 'mdzs_jl',
        label: 'Jin Ling',
        src: './images/preview_chara_mdzs_06_jl.png',
        print: './export/print_chara_mdzs_06_jl.png'
      },
      {
        id: 'mdzs_jzx',
        label: 'Jin Zixuan',
        src: './images/preview_chara_mdzs_07_jzx.png',
        print: './export/print_chara_mdzs_07_jzx.png'
      },
      {
        id: 'mdzs_ljy',
        label: 'Lan Jingyi',
        src: './images/preview_chara_mdzs_08_ljy.png',
        print: './export/print_chara_mdzs_08_ljy.png'
      },
      {
        id: 'mdzs_lsz',
        label: 'Lan Sizhui',
        src: './images/preview_chara_mdzs_09_lsz.png',
        print: './export/print_chara_mdzs_09_lsz.png'
      },
      {
        id: 'mdzs_lwj',
        label: 'Lan Wangji',
        src: './images/preview_chara_mdzs_10_lwj.png',
        print: './export/print_chara_mdzs_10_lwj.png'
      },
      {
        id: 'mdzs_lxc',
        label: 'Lan Xichen',
        src: './images/preview_chara_mdzs_11_lxc.png',
        print: './export/print_chara_mdzs_11_lxc.png'
      },
      {
        id: 'mdzs_lqy',
        label: 'Luo Qingyang / MianMian',
        src: './images/preview_chara_mdzs_12_lqy.png',
        print: './export/print_chara_mdzs_12_lqy.png'
      },
      {
        id: 'mdzs_nhs',
        label: 'Nie Huaisang',
        src: './images/preview_chara_mdzs_13_nhs.png',
        print: './export/print_chara_mdzs_13_nhs.png'
      },
      {
        id: 'mdzs_nmj',
        label: 'Nie Mingjue',
        src: './images/preview_chara_mdzs_14_nmj.png',
        print: './export/print_chara_mdzs_14_nmj.png'
      },
      {
        id: 'mdzs_oyzz',
        label: 'Ouyang Zizhen',
        src: './images/preview_chara_mdzs_15_oyzz.png',
        print: './export/print_chara_mdzs_15_oyzz.png'
      },
      {
        id: 'mdzs_sl',
        label: 'Song Lan',
        src: './images/preview_chara_mdzs_16_sl.png',
        print: './export/print_chara_mdzs_16_sl.png'
      },
      {
        id: 'mdzs_wwx',
        label: 'Wei Wuxian',
        src: './images/preview_chara_mdzs_17_wwx.png',
        print: './export/print_chara_mdzs_17_wwx.png'
      },
      {
        id: 'mdzs_wn',
        label: 'Wen Ning',
        src: './images/preview_chara_mdzs_18_wn.png',
        print: './export/print_chara_mdzs_18_wn.png'
      },
      {
        id: 'mdzs_wq',
        label: 'Wen Qing',
        src: './images/preview_chara_mdzs_19_wq.png',
        print: './export/print_chara_mdzs_19_wq.png'
      },
      {
        id: 'mdzs_xxc',
        label: 'Xiao Xingchen',
        src: './images/preview_chara_mdzs_20_xxc.png',
        print: './export/print_chara_mdzs_20_xxc.png'
      },
      {
        id: 'mdzs_xy',
        label: 'Xue Yang',
        src: './images/preview_chara_mdzs_21_xy.png',
        print: './export/print_chara_mdzs_21_xy.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- OUAT (Curious Archer) --',
        divider: true,
      },
      {
        id: 'ouat_alice',
        label: 'Alice / Tilly',
        src: './images/preview_chara_ouat_01_alice.png',
        print: './export/print_chara_ouat_01_alice.png'
      },
      {
        id: 'ouat_robin',
        label: 'Robin / Margot',
        src: './images/preview_chara_ouat_02_robin.png',
        print: './export/print_chara_ouat_02_robin.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- Sk8 the Infinity --',
        divider: true,
      },
      {
        id: 'sk8_ainosuke',
        label: 'Ainosuke / ADAM',
        src: './images/preview_chara_sk8_01_ainosuke.png',
        print: './export/print_chara_sk8_01_ainosuke.png'
      },
      {
        id: 'sk8_hiromi',
        label: 'Hiromi / Shadow',
        src: './images/preview_chara_sk8_02_shadow.png',
        print: './export/print_chara_sk8_02_shadow.png'
      },
      {
        id: 'sk8_cherry',
        label: 'Kaoru / Cherry Blossom',
        src: './images/preview_chara_sk8_03_cherry.png',
        print: './export/print_chara_sk8_03_cherry.png'
      },
      {
        id: 'sk8_joe',
        label: 'Kojiro / JOE',
        src: './images/preview_chara_sk8_04_joe.png',
        print: './export/print_chara_sk8_04_joe.png'
      },
      {
        id: 'sk8_langa',
        label: 'Langa',
        src: './images/preview_chara_sk8_05_langa.png',
        print: './export/print_chara_sk8_05_langa.png'
      },
      {
        id: 'sk8_miya',
        label: 'Miya',
        src: './images/preview_chara_sk8_06_miya.png',
        print: './export/print_chara_sk8_06_miya.png'
      },
      {
        id: 'sk8_reki',
        label: 'Reki',
        src: './images/preview_chara_sk8_07_reki.png',
        print: './export/print_chara_sk8_07_reki.png'
      },
      {
        id: 'sk8_tadashi',
        label: 'Tadashi',
        src: './images/preview_chara_sk8_08_tadashi.png',
        print: './export/print_chara_sk8_08_tadashi.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- Spy X Family --',
        divider: true,
      },
      {
        id: 'sxf_anya1',
        label: 'Anya Forger (Casual)',
        src: './images/preview_chara_sxf_01_anya1.png',
        print: './export/print_chara_sxf_01_anya1.png'
      },
      {
        id: 'sxf_anya2',
        label: 'Anya Forger (Uniform)',
        src: './images/preview_chara_sxf_02_anya2.png',
        print: './export/print_chara_sxf_02_anya2.png'
      },
      {
        id: 'sxf_becky',
        label: 'Becky Blackbell',
        src: './images/preview_chara_sxf_03_becky.png',
        print: './export/print_chara_sxf_03_becky.png'
      },
      {
        id: 'sxf_bond',
        label: 'Bond Forger',
        src: './images/preview_chara_sxf_04_bond.png',
        print: './export/print_chara_sxf_04_bond.png'
      },
      {
        id: 'sxf_damian',
        label: 'Damian Desmond',
        src: './images/preview_chara_sxf_05_damian.png',
        print: './export/print_chara_sxf_05_damian.png'
      },
      {
        id: 'sxf_loid',
        label: 'Loid Forger',
        src: './images/preview_chara_sxf_06_loid.png',
        print: './export/print_chara_sxf_06_loid.png'
      },
      {
        id: 'sxf_yor',
        label: 'Yor Forger',
        src: './images/preview_chara_sxf_07_yor.png',
        print: './export/print_chara_sxf_07_yor.png'
      },
      {
        label: '---------------------------',
        divider: true,
      },
      {
        label: '-- Voltron (VLD) --',
        divider: true,
      },
      {
        id: 'vld_allura',
        label: 'Allura',
        src: './images/preview_chara_vld_01_allura.png',
        print: './export/print_chara_vld_01_allura.png'
      },
      {
        id: 'vld_coran',
        label: 'Coran',
        src: './images/preview_chara_vld_02_coran.png',
        print: './export/print_chara_vld_02_coran.png'
      },
      {
        id: 'vld_hunk',
        label: 'Hunk',
        src: './images/preview_chara_vld_03_hunk.png',
        print: './export/print_chara_vld_03_hunk.png'
      },
      {
        id: 'vld_keith',
        label: 'Keith',
        src: './images/preview_chara_vld_04_keith.png',
        print: './export/print_chara_vld_04_keith.png'
      },
      {
        id: 'vld_kosmo',
        label: 'Kosmo / Space Wolf',
        src: './images/preview_chara_vld_05_kosmo.png',
        print: './export/print_chara_vld_05_kosmo.png'
      },
      {
        id: 'vld_krolia',
        label: 'Krolia',
        src: './images/preview_chara_vld_06_krolia.png',
        print: './export/print_chara_vld_06_krolia.png'
      },
      {
        id: 'vld_lance',
        label: 'Lance',
        src: './images/preview_chara_vld_07_lance.png',
        print: './export/print_chara_vld_07_lance.png'
      },
      {
        id: 'vld_lotor',
        label: 'Lotor',
        src: './images/preview_chara_vld_08_lotor.png',
        print: './export/print_chara_vld_08_lotor.png'
      },
      {
        id: 'vld_pidge',
        label: 'Pidge',
        src: './images/preview_chara_vld_09_pidge.png',
        print: './export/print_chara_vld_09_pidge.png'
      },
      {
        id: 'vld_romelle',
        label: 'Romelle',
        src: './images/preview_chara_vld_10_romelle.png',
        print: './export/print_chara_vld_10_romelle.png'
      },
      {
        id: 'vld_shiro_bw',
        label: 'Shiro (B&W Hair)',
        src: './images/preview_chara_vld_11_shirobw.png',
        print: './export/print_chara_vld_11_shirobw.png'
      },
      {
        id: 'vld_shiro_w',
        label: 'Shiro (White Hair)',
        src: './images/preview_chara_vld_12_shirow.png',
        print: './export/print_chara_vld_12_shirow.png'
      }
],
caption: [
  {
    id: 'Caption',
    label: 'Default',
    src: './images/preview_plate.png',
    print: './export/print_caption.png'
  }
],
header: './export/print_header.png',
envelope: './export/print_addon_envelope.png'
}

export default layers