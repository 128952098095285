import { FaTimes } from 'react-icons/fa'

const Popup = ( { popupID, popupTitle, popupContent } ) => {

    return (
        <div id={popupID} className="popUp">
        <div className="popUp_box">
          <h3 className="title">{popupTitle}</h3>
            <div className="content">
                {popupContent}
            </div>
          <a href="#clear" className="btn close">< FaTimes />&nbsp;Close</a>
        </div>
      </div>
    )
    
}

export default Popup
