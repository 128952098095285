import { FaTwitter, FaShoppingBag } from 'react-icons/fa'

const WindowFooter = () => {
    return (
        <div className="customizeFooter">
            <div className="footerLeft">
                <a href="https://art.curiousest.net" target="_blank" rel="noreferrer" title="Ali @ Curiousest.net">
                    Art &amp; Design &copy; Ali @ Curiousest.Net
                </a>
                <br/>
                <a href="https://twitter.com/_artsy_alice" title="Follow on Twitter"
                    rel="noreferrer"  target="_blank">
                    <FaTwitter /> Twitter
                </a>
                <a href="https://ko-fi.com/artsyalice/shop" title="Browse the Ko-fi Shop"
                    rel="noreferrer"  target="_blank">
                    <FaShoppingBag /> Ko-fi Shop
                </a>
            </div>
            <div className="footerRight">
                <a href='https://ko-fi.com/A7744JWA' rel="noreferrer" target='_blank'><img height='36' style={{ border: '0px', height: '36px' }} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
            </div>
        </div>
    )
}

export default WindowFooter
