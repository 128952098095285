import { FaDownload } from 'react-icons/fa'
import { FaImage } from 'react-icons/fa'
import { FaPaintBrush } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'
import { FaComment } from 'react-icons/fa'
import ControlCheck from './ControlCheck'

import ControlRadioGroup from './ControlRadioGroup'
import ControlSelect from './ControlSelect'
import ControlText from './ControlText'


const WindowControl = ( { layers_src, defaults, onChangeLayer, onExport } ) => {

    const saveAndPrint = (e) => {
      e.preventDefault()
      onExport()
    }
    return (
        <div className="customizeControl">
          <form>
          <div className="controlGroup">
            <h2 className="layerName"><FaImage /> &nbsp;Background</h2>
            <ControlRadioGroup
                controlName="control_background"
                options={layers_src.background}
                selected={defaults.background}
                onChangeLayer={onChangeLayer} />
          </div>         
          <div className="controlGroup">
            <h2 className="layerName">< FaPaintBrush /> &nbsp;Base Color</h2> 
            <ControlRadioGroup
                controlName="control_base"
                options={layers_src.base} 
                selected={defaults.base}
                onChangeLayer={onChangeLayer} />
          </div>
          <div className="controlGroup">
            <h2 className="layerName">< FaUser /> &nbsp;Characters</h2>
            
            <ControlSelect
                controlName="control_chara_A"
                placeholderText="Select character (A)"
                options={layers_src.character}
                selected={defaults.charaA}
                onChangeLayer={onChangeLayer}  />
            <ControlSelect
                controlName="control_chara_B"
                placeholderText="Select character (B)"
                options={layers_src.character}
                selected={defaults.charaB}
                onChangeLayer={onChangeLayer}  />
            <ControlSelect
                controlName="control_chara_C"
                placeholderText="Select character (C)"
                options={layers_src.character}
                selected={defaults.charaC}
                onChangeLayer={onChangeLayer}  />
          </div>
          <div className="controlGroup">
            <h2 className="layerName"><FaComment /> &nbsp;Message</h2> 
            <ControlText
                controlName="control_message"
                defaultText="Thinking of you!"
                placeholderText="Your Message"
                onChangeLayer={onChangeLayer} />
            <ControlCheck
              controlName="control_addons"
              placeholderText="Add envelope and card"
              options={layers_src.addons}
              onChangeLayer={onChangeLayer} />
          </div>
          <div className="controlGroup" style={{ width: "100%" }}>
            <button onClick={saveAndPrint}><FaDownload /> Download</button>
          </div>
          </form>
        </div>
    )
}

export default WindowControl
