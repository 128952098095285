import { Page, Text, Image, View, Document, Font, StyleSheet } from '@react-pdf/renderer'
import Amita from './fonts/Amita-Bold.ttf'

Font.register({ family: 'Amita', src: Amita });

// Create styles
const styles = StyleSheet.create({
    page: {
      paddingVertical: 20, 
      flexDirection: 'column',
      backgroundColor: 'white',
      overflow: 'hidden',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    section_addon: {
        display: 'flex',
        width: '7.5in',
        height: '9in',
        paddingVertical: 20
    },
    header: {
        width: '7in',
        height: '3.25in'
    },
    addon: {
        width: '7.5in',
        height: '9in',
    },
    section_layers: {
      width: '7.2in',
      height: '7in',
      display: 'block',
      position: 'relative'
    },
    layer_bg: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '5.75in',
        height: '5.5in'
    },
    layer_base: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '4.35in',
        height: '7in'
    },
    layer_charaA: {
        position: 'absolute',
        top: '4.2in',
        left: '0',
        width: '1in',
        height: 'auto'
    },
    layer_charaB: {
        position: 'absolute',
        top: '4.2in',
        left: '1.02in',
        width: '1in',
        height: 'auto'
    },
    layer_charaC: {
        position: 'absolute',
        top: '4.2in',
        left: '2.04in',
        width: '1in',
        height: 'auto'
    },
    layer_caption: {
        position: 'absolute',
        bottom: '0',
        left: '0.3in',
        width: '2.31in',
        height: '0.73in',
        overflow: 'hidden',
        fontFamily: 'Amita'
    },
    layer_caption_text: {
        position: 'absolute',
        display: 'block',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        paddingTop: '5px',
        paddingHorizontal: '8px',
        fontSize: '13.5pt',
        color: '#4d4d54',
        textAlign: 'center',
    },
    layer_caption_text_shadow: {
        position: 'absolute',
        display: 'block',
        top: '1px',
        left: '1px',
        width: '100%',
        height: '100%',
        paddingTop: '5px',
        paddingHorizontal: '8px',
        fontSize: '13.5pt',
        color: '#FFF',
        textAlign: 'center',
    },
    layer_caption_img: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '2.31in',
        height: '0.73in',
    },
    section_footer: {
        marginTop: '15',
        fontSize: '7.5pt',
        color: 'gray',
        textAlign: 'center'
    },
    
  });

  // Create Document Component
const MyDocument = ( { layers_src, layers } ) => {

    const bg = layers.background
    const base = layers.base
    const charaA = layers.charaA
    const charaB = layers.charaB
    const charaC = layers.charaC
    const caption = layers.caption
    const addons = layers.addons

    const img_header = layers_src.header
    const img_envelope = layers_src.envelope
    const img_card = layers_src.background[bg].print_card
    const img_bg = layers_src.background[bg].print
    const img_base = layers_src.base[base].print
    const img_charaA = layers_src.character[charaA].print
    const img_charaB = layers_src.character[charaB].print
    const img_charaC = layers_src.character[charaC].print
    const img_caption = layers_src.caption[caption].print
    
    return(
    <Document>
      <Page size="A4" style={styles.page} wrap={false} >
        <View style={styles.section_header}>
            <Image src={ img_header } style={styles.header}  />
        </View>
        <View style={styles.section_layers}>
            <Image src={ img_bg } style={styles.layer_bg} />
            <Image src={ img_base } style={styles.layer_base}  />
            {
                img_charaA !== ''  &&
                <Image src={ img_charaA } style={styles.layer_charaA} />
            }
            {
                img_charaB !== '' &&
                <Image src={ img_charaB } style={styles.layer_charaB} />
            }
            {
                img_charaC !== '' &&
                <Image src={ img_charaC } style={styles.layer_charaC} />
            }
            <View style={styles.layer_caption}>
                <Image src={ img_caption } style={styles.layer_caption_img} />
                <Text style={ styles.layer_caption_text_shadow } >{layers.message}</Text>
                <Text style={ styles.layer_caption_text } >{layers.message}</Text>
            </View>
        </View>
        <View style={styles.section_footer} >
           <Text>FOR PERSONAL USE ONLY. NOT FOR SALE. PLEASE DO NOT REPOST, REDISTRIBUTE OR ARCHIVE WITHOUT PERMISSION.{"\n"}
           All characters are copyright of their respective owners. Art &amp; Design &copy; Ali @ Curiousest.Net</Text>
        </View>
      </Page>
      { addons &&
      <>
        <Page size="A4" style={styles.page} wrap={false} >
            <View style={styles.section_header}>
                <Image src={ img_card } style={styles.addon} />
            </View>
            <View style={styles.section_footer} >
            <Text>FOR PERSONAL USE ONLY. NOT FOR SALE. PLEASE DO NOT REPOST, REDISTRIBUTE OR ARCHIVE WITHOUT PERMISSION.{"\n"}
            Art &amp; Design &copy; Ali @ Curiousest.Net</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page} wrap={false} >
            <View style={styles.section_header}>
                <Image src={ img_envelope } style={styles.addon} />
            </View>
            <View style={styles.section_footer} >
            <Text>FOR PERSONAL USE ONLY. NOT FOR SALE. PLEASE DO NOT REPOST, REDISTRIBUTE OR ARCHIVE WITHOUT PERMISSION.{"\n"}
            Art &amp; Design &copy; Ali @ Curiousest.Net</Text>
            </View>
        </Page>
      </>
      }
    </Document>
    )
}

  export default MyDocument