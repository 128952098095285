import { useState } from 'react'
import layers from './components/Layers.js'
import WindowHeader from './components/WindowHeader'
import WindowPreview from './components/WindowPreview'
import WindowControl from './components/WindowControl'
import WindowFooter from './components/WindowFooter'
import Popup from './components/Popup'
import PopupAbout from './components/PopupAbout'
import PopupHelp from './components/PopupHelp'

import MyDocument from './components/Document'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function App() {

  const initialState = ({
    background: 0,
    base: 0,
    charaA: 0,
    charaB: 0,
    charaC: 0,
    caption: 0,
    message: 'Thinking of you!',
    addons: false
  })

  const [ layers_Preview, setLayers] = useState(initialState)

  const changeLayer = ( layerName, newValue ) => {
     if( layerName === 'control_base' ){
        setLayers((prevState) => ({
          ...prevState,
          base: newValue
          })
        )
      } else if ( layerName === 'control_background' ) {
        setLayers((prevState) => ({
          ...prevState,
          background: newValue
          })
        )
      } else if ( layerName === 'control_chara_A' ) {
        setLayers((prevState) => ({
          ...prevState,
          charaA: newValue
          })
        )
      } else if ( layerName === 'control_chara_B' ) {
        setLayers((prevState) => ({
          ...prevState,
          charaB: newValue
          })
        )
      } else if ( layerName === 'control_chara_C' ) {
        setLayers((prevState) => ({
          ...prevState,
          charaC: newValue
          })
        )
      } else if ( layerName === 'control_message' ) {
        setLayers((prevState) => ({
          ...prevState,
          message: newValue
          })
        )
      } else if ( layerName === 'control_addons' ) {
        setLayers((prevState) => ({
          ...prevState,
          addons: newValue
          })
        )
      }
    
  }

  const generatePDFFilename = () => {
    let prefix = 'snowglobe_'
    let base = layers.base[layers_Preview.base].id
    let charas = layers.character[layers_Preview.charaA].id + '_' + layers.character[layers_Preview.charaB].id + '_' + layers.character[layers_Preview.charaC].id 
    let bg = layers.background[layers_Preview.background].id
    let filename = prefix + base + '_' + charas + '_' + bg + '_A4.pdf'
    return filename
  }

  const generatePDFDocument = async () => {
    const blob = await pdf((
        <MyDocument
            layers_src={layers}
            layers={layers_Preview}
        />
    )).toBlob();
    let filename = generatePDFFilename()
    saveAs(blob, filename );
  };

  return (
    <>           
      <div className="customizeWindow">
        <WindowHeader />
        <WindowPreview layers_src={layers} preview={layers_Preview} />
        <WindowControl layers_src={layers} defaults={layers_Preview} onChangeLayer={changeLayer} onExport={generatePDFDocument} />
        <WindowFooter />
      </div>
      <Popup popupID="about" popupTitle="DIY Snow Globe Box Card Maker" popupContent={<PopupAbout/>} />
      <Popup popupID="help" popupTitle="How To Use" popupContent={<PopupHelp/>} />
    </>
  );
}

export default App;
