const PopupHelp = () => {
    return (
        <div>
            <p>This app allows you to customize the appearance of your DIY Snow Globe Box Card, then generates a PDF template file for you to print and assemble!</p>
            <p><b>CUSTOMIZE:</b><br/>
            &bull; Select the background &amp; base color.<br/>
            &bull; Pick up to three characters.<br/>Select "Custom" if you want to draw/insert your own, or "No character" to remove from design.<br/>
            &bull; Type your box card message/sentiment.<br/>Leave blank if you want to hand-write your own.</p>
            <p><b>EXPORT:</b><br/>
            &bull; Click on the <u>Download</u> button to generate your design. This make take up to a few seconds.<br/>
            Download will start automatically when the file is ready.</p>
            <p><b>PRINT &amp; CREATE</b>:<br/>
            &bull; Print your downloaded PDF on A4 or Letter size cardstock or photo paper.<br/>
            Assembly instructions are included in the template.</p>
            <hr/>
            <p>For requests, questions or concerns:<br/>
              <a href="https://twitter.com/_artsy_alice" rel="noreferrer" target="_blank">Twitter</a>
              &nbsp;&bull; <a href="https://www.instagram.com/_artsy_alice/" rel="noreferrer" target="_blank">Instagram</a>
              &nbsp;&bull; <a href="https://artsy-alice.tumblr.com" rel="noreferrer" target="_blank">Tumblr</a>
              &nbsp;&bull; <a href="https://curiousest.net" rel="noreferrer" target="_blank">Curiousest.Net</a>
            </p>
            <hr/>
            <p align="center" style={{ fontSize: '8pt' }}>All characters are copyright of their respective owners. This app &amp; the templates are for personal use only. Any sale or redistribution for profit are not authorized.
            <br/>Art &amp; design &copy; Ali @ Curiousest.Net</p>
        </div>
    )
}

export default PopupHelp
