const ControlText = ( { controlName, placeholderText, defaultText, onChangeLayer, reset } ) => {
    
    const handleChange = function (event) {
        let newText = event.target.value
        onChangeLayer( controlName, newText )
    }
    
    return (
        <textarea id={controlName} name={controlName}
              placeholder={placeholderText}
              defaultValue={defaultText}
              onChange={handleChange} />
        
    )
}

export default ControlText
