import {FaInfoCircle, FaTwitter} from 'react-icons/fa'
import {FaQuestionCircle} from 'react-icons/fa'

const WindowPreview = ( { layers_src, preview } ) => {

    const bg = preview.background
    const base = preview.base
    const charaA = preview.charaA
    const charaB = preview.charaB
    const charaC = preview.charaC
    const caption = preview.caption

    return (
        <div className="customizePreview">
            <div className="snowglobe">
                <img className="layer bgBase" src={ layers_src.background[bg].src } alt={ layers_src.background[bg].label } />
                <img className="layer bgElement" src={ layers_src.background[bg].src_bgElem} alt={ layers_src.background[bg].label } />
                <div className="layer charas">
                {
                    charaA > 0 &&
                    <img className="charaA" src={ layers_src.character[charaA].src } alt={ layers_src.character[charaA].label } />
                }
                {
                    charaB > 0 &&
                    <img className="charaB" src={ layers_src.character[charaB].src } alt={ layers_src.character[charaB].label } />
                }
                {
                    charaC > 0 &&
                    <img className="charaC" src={ layers_src.character[charaC].src } alt={ layers_src.character[charaC].label } />
                }
                </div>
                <img className="layer fgElement" src={ layers_src.background[bg].src_fg } alt={ layers_src.background[bg].label } />
                <img className="layer base" src={ layers_src.base[base].src } alt={ layers_src.base[base].label }  />
                <img className="layer caption" src={ layers_src.caption[caption].src } alt={ layers_src.caption[caption].label } />
                <div className="layer captionText">
                    <span className="captionText">
                        {preview.message}
                    </span>
                </div>
            </div>
            <div className="popup-buttons">
                <a href="#help" className="btn popup help" title="How to Use"><FaQuestionCircle />&nbsp; Help</a>
                <a href="#about" className="btn popup about" title="About"><FaInfoCircle /></a>
                <a href="https://twitter.com/intent/tweet?text=Ali's DIY Snow Globe Box Card Maker&amp;url=https://snowglobe.curiousest.net" target="_blank" rel="noreferrer" title="Share on Twitter!" className="btn popup tweet"><FaTwitter /></a>
            </div>
        </div>
    )
}

export default WindowPreview
