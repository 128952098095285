import React from 'react'

const ControlCheck = ({ controlName, placeholderText, onChangeLayer, options }) => {

    const handleChange = function (event) {
        let newValue = event.target.checked
        onChangeLayer( controlName, newValue )
    }

    return (
        <div className="control_checkbox" align="left"> 
            <input name={controlName} id={controlName} type="checkbox" onClick={handleChange} />
            <label htmlFor={controlName}> &nbsp;{placeholderText}</label>
        </div>
    )
}

export default ControlCheck
