import React from 'react'
import {FaSnowflake} from 'react-icons/fa'

const WindowHeader = () => {
    return (
        <div className="customizeHeader">
          <h1 className="window-title"><FaSnowflake /> Customize your Box Card</h1>

        </div>
    )
}

export default WindowHeader
