const ControlRadioGroup = ( { controlName, selected, options, onChangeLayer } ) => {
  
  const handleChange = function (event) {
    let newValue = event.target.value
    onChangeLayer( controlName, newValue )
  }

  return (
        <div className={'radioGroup ' + controlName} >
            {
                options.map(( img, index ) => (
                    <div className="radioOption" key={index} >
                    <input  type="radio" name={controlName} id={img.id} value={index}
                      defaultChecked={index === selected}
                      onChange={handleChange}  />
                    <label htmlFor={img.id}>
                      <img src={img.thumbnail} alt={img.label} title={img.label} /></label>
                    </div>
                  ))
            }
        </div>
    )
}

export default ControlRadioGroup
