const PopupAbout = () => {
    return(
        <div>
            <p align="center">A personal project by Ali <br/>
            (<a href="https://snowglobe.curiousest.net">https://snowglobe.curiousest.net</a>)<br/>
            Customize and download a printable PDF of your own do-it-yourself snow globe box card.<br/></p>
            <hr/>
            <p><b>UPDATE LOG</b></p>
            <p>&bull; 05/26/2022 - Added Jujutsu Kaisen &amp; Spy X Family character sets.<br/>
              &bull; 05/25/2022 - Updated app to fit 3 characters. Added 2 new backgrounds (Sunny Park &amp; Cherry Blossoms) and 1 new base color (White).<br/>
              &bull; 12/12/2021 - Added Alikou (Magi) &amp; Curious Archer (OUAT) character/ship sets.<br/>
              &bull; 12/6/2021 - Added MDZS character set.<br/>
              &bull; 12/3/2021 - App launch ft. VLD and Sk8 characters. More characters to follow.<br/>
              &bull; 11/27/2021 - Test launch.
            </p>
            <hr/>
            <p>For requests, questions or concerns:<br/>
              <a href="https://twitter.com/_artsy_alice" rel="noreferrer" target="_blank">Twitter</a>
              &nbsp;&bull; <a href="https://www.instagram.com/_artsy_alice/" rel="noreferrer" target="_blank">Instagram</a>
              &nbsp;&bull; <a href="https://artsy-alice.tumblr.com" rel="noreferrer" target="_blank">Tumblr</a>
              &nbsp;&bull; <a href="https://curiousest.net" rel="noreferrer" target="_blank">Curiousest.Net</a>
            </p>
            <hr/>
            <p align="center" style={{ fontSize: '8pt' }}>All characters are copyright of their respective owners. This app &amp; the templates are for personal use only. Any sale or redistribution for profit are not authorized.
            <br/>Art &amp; design &copy; Ali @ Curiousest.Net</p>
        </div>
  )
}

export default PopupAbout