const ControlSelect = ( { controlName, placeholderText, options, selected, onChangeLayer } ) => {
    
    const handleChange = function (event) {
        let newValue = event.target.value
        onChangeLayer( controlName, newValue )
    }
    
    return (
        
        <select className={controlName} onChange={handleChange} defaultValue={selected}
            placeholder={placeholderText} >
            {
                 options.map(( choice, index ) => (
                    <option key={index} value={index} disabled={choice.divider} >
                        {choice.label}
                    </option>
                  ))
            }
        </select>
    )
}

export default ControlSelect
